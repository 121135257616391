<template>
  <div class="hero">
    <img src="@/assets/hero.png"/>
  </div>
</template>

<script>
export default {
  name: 'HeroImage'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
  width: 100%;
  max-width: 700px;
}
</style>
