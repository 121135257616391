<template>
    <div class="content-card" :class="align">
        <div class="card-content">
            <h2 class="card-title" v-if="title">
                {{  title  }}
            </h2>
            <slot></slot>
            <div class="card-btn" v-if="modal">
                <InfoButton @click="$emit('show-modal', modal)">Learn More</InfoButton>
            </div>
        </div>
        <div class="card-img" v-if="img">
            <img :src="img" />
        </div>
    </div>
</template>

<script>
import InfoButton from './InfoButton.vue'

export default {
    name: 'SectionTitle',
    components: {
        InfoButton,
    },
    props: {
        title: String,
        img: String,
        align: {
            type: String,
            default: 'center'
        },
        modal: String,
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/common.scss";

$text-width: 65%;

/* CARD CONTAINER */

.content-card {
    position: relative;
    display: grid;
    grid-gap: 20px;
    grid-template-rows: 100%;

    width: 90%;
    height: 300px;
    max-width: 800px;

    margin: 20px 5px;
    padding: 36px;
    border-radius: $border-radius;

    color: $darkblue;
    background-color: $lightblue;

    transition: all 0.3s ease-out,
                transform 0.2s ease-out;
}

.content-card.center {
    grid-template-columns: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    grid-template-areas:
        "content";

}

.content-card.left {
    grid-template-columns: $text-width auto;

    grid-template-areas:
        "content img";
}

.content-card.right {
    grid-template-columns: auto $text-width;

    grid-template-areas:
        "img content";
}

.content-card:hover {
    transform: scale(1.005);
}

/* CONTENT AREA */

.card-title {
    font-family: $title-font;
    font-size: 2.25rem;
}

.content-card.center .card-title {
    width: 100%;
    text-align: center;
}

.card-content {
    grid-area: content;

    @extend .flex;
    @extend .flex-col;
    @extend .flex-left;


    padding: 10px 0px;
    gap: 20px;
}

.card-btn {
    margin-left: auto;
    margin-top: auto;
}

/* IMAGE AREA */

.card-img {
    grid-area: img;
    background-color: black;

    border-radius: $border-radius;
}

.card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: inherit;
}

/* MOBILE */

@media only screen and (max-width: $mobile-width) {
    $image-height: 200px;

    .content-card {
        grid-template-rows: $image-height auto !important;
        grid-template-columns: 100% !important;
        grid-template-areas:
            "img"
            "content" !important;

        padding: 20px;
        gap: 15px;

        height: auto;
    }

    .card-title {
        font-size: 2rem;
        width: 100%;
        text-align: center;
    }

    .card-content {
        padding: 0;
        text-align: center;
    }

    .card-btn {
        margin-left: auto;
        margin-right: auto;
    }
}

</style>
