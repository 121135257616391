<template comments>
    <a id="contact" :href="emailLink"> contact<!-- asdk;jl]W2nnwelw -->&commat;<!-- ;uheq8iw.;beaspe -->lucillexoxo<!-- 29nd-vuv187aBSX/ -->&#46;com</a>
</template>

<script>
export default {
    data() {
        return {
            email: "Y29udGFjdEBsdWNpbGxleG94by5jb20="
        };
    },
    computed: {
        emailLink() {
            const decodedEmail = atob(this.email);
            return "mailto:" + decodedEmail;
        }
    }
};
</script>

<style scoped>
</style>
