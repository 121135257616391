<template>
    <div @aria-role="button" class="button info-button">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'InfoButton'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/common.scss";

.info-button {
    padding: 10px 30px;

    width: fit-content;
    height: fit-content;

    border-radius: $border-radius;

    background-color: $darkpink;

    color: $white;
    font-family: $title-font;
    font-weight: bold;
    font-size: 1.33rem;

    cursor: pointer;
    user-select: none;

    transition: all 0.3s ease-out,
                box-shadow 0.3s ease-out,
                transform 0.2s ease-out;

    &:hover {
        filter: saturate(90%);
        box-shadow: 0 0 2px $darkpink;
        transform: scale(1.025);
    }

    &:active {
        filter: saturate(80%);
        box-shadow: 0 0 1px $darkpink;
        transform: scale(1.0125);
    }
}
</style>
