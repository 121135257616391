<template>
    <span class="inline-citation">
        <a :href="href" target="_blank" rel="noreferrer noopener">
            <slot></slot>
        </a>
    </span>
</template>

<script>
export default {
    name: 'HeroImage',
    props: {
        href: String,
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.inline-citation {
    display: inline;
    vertical-align: super;
    font-size: 0.8em;
}

a {
    text-decoration: none;
}
</style>
