<template>
  <nav id="welcome" class="flex">
    <!-- Navbar -->
    <div class="nav-content flex">
      <div aria-role="button" class="nav-btn">
        <a href="#welcome">
          Welcome
        </a>
      </div>
      <div aria-role="button" class="nav-btn">
        <a href="#events">
          Events
        </a>
      </div>
      <div aria-role="button" class="nav-btn">
        <a href="#about">
          About Us
        </a>
      </div>
      <div aria-role="button" class="nav-btn">
        <a href="#contact">
          Contact
        </a>
      </div>
    </div>
  </nav>
  <!-- Hero -->
  <div id="hero" class="flex flex-col">
    <div class="hero-bg"></div>
    <HeroImage />
    <p class="blurb">We’re <span class="pink-text">Violencia Exclamation Point</span> and <span
        class="pink-text">Lucille</span>, two seasoned drag performers and hosts from Boston. We have a wide variety of
      experience hosting drag events and we’d love to partner with you to organize one at your venue!</p>
  </div>
  <main class="flex flex-col flex-top">
    <!-- Events -->
    <div id="events" class="content-section">
      <SectionTitle>Events We Host</SectionTitle>
      <!-- Drag Brunch -->
      <ContentCard @show-modal="showModal" modal="dragbrunch" title="Drag Brunch" img="./img/pexels-stockphotoartist-2220618.jpg" align="left" class="dragbrunch-card">
        Drag brunch combines great food & cocktails with a rotation of talented, diverse local drag performers for a unique and fun event!
      </ContentCard>
      <!-- Drag Bingo / Trivia -->
      <ContentCard @show-modal="showModal" modal="dragbingo" title="Drag Bingo/Trivia" img="./img/pexels-tatiana-azatskaya-8803363.jpg" align="right" class="dragbingo-card">
        Drag bingo or trivia is a great way to bring in guests for a more casual and interactive drag experience.
      </ContentCard>
    </div>
    <div id="about" class="content-section">
      <!-- About -->
      <SectionTitle>About Us</SectionTitle>
      <BioCard title="Violencia Exclamation Point" img="./img/Violencia.png" align="left">
        <span class="pink-text">Violencia Exclamation Point</span> is an experienced drag performer and host. She is founder and host of the <a href="https://www.instagram.com/theservenetwork/?hl=en">Serve Network</a>, an online platform for organizing and uplifting drag queens and their events. Violencia most recently hosted <i>Serve Thursdays</i>, a weekly drag showcase at <a href="https://legacybos.com/">Legacy Boston</a>. She was also featured on the third season of <i><span class="pinktext">The Boulet Brothers' Dragula</span></i>.<InlineCitation href="https://thotyssey.com/2021/02/06/on-point-with-violencia-exclamation-point/">[1]</InlineCitation><InlineCitation href="https://www.bostonmagazine.com/news/2020/03/10/boston-drag/">[2]</InlineCitation><InlineCitation href="https://www.wbur.org/news/2020/09/30/saying-goodbye-to-queer-spots-machine-ramrod-fenway">[3]</InlineCitation><InlineCitation href="https://fanexpohq.com/the-fhq/2021-cotm-violencia-exclamation-point/">[4]</InlineCitation>
        <template v-slot:social-icons>
          <SocialIcon href="https://www.instagram.com/violenciaexclamationpoint/" icon="instagram"></SocialIcon>
          <SocialIcon href="https://www.facebook.com/violencia.exclamationpoint.5/" icon="facebook"></SocialIcon>
          <SocialIcon href="https://twitter.com/V_Exclamation" icon="twitter"></SocialIcon>
        </template>
      </BioCard>
      <br/>
      <BioCard title="Lucille" img="./img/Lucille.png" align="left">
        <span class="pink-text">Lucille</span> has been a drag performer in Boston for 11 years and has hosted drag brunches, bingo nights, and trivia nights across the city. Lucille hosted several virtual drag events during lockdown to keep the community connected through the pandemic. Her livestreams have raised almost $20,000 for charity, and she has previously been a featured partner of brands such as <a href="https://www.bhvr.com/">Behaviour Interactive</a> and <a href="https://www.anastasiabeverlyhills.com/">Anastasia Beverly Hills</a>.<InlineCitation href="https://www.portersquarebooks.com/event/david-valdes-author-finding-my-elf-conversation-ashley-waring">[5]</InlineCitation><InlineCitation href="https://tiltify.com/@lucillexoxo/profile">[6]</InlineCitation>
        <template v-slot:social-icons>
          <SocialIcon href="https://www.instagram.com/itslucillexoxo/" icon="instagram"></SocialIcon>
          <SocialIcon href="https://www.youtube.com/LucilleXOXO" icon="youtube"></SocialIcon>
        </template>
      </BioCard>
    </div>
    <!-- Contact -->
    <div id="contact" class="content-section">
      <!--<SectionTitle>Contact Us</SectionTitle>-->
      <ContentCard title="CONTACT US" class="contact-card">
        <p>
          Want to get in touch? Reach out to <ContactEmail /> and we can chat about your own event. Looking forward to working with you!
        </p>
      </ContentCard>
    </div>
  </main>
  <footer>
    © 2024 all rights reserved.
  </footer>
  <!-- Modal -->
  <InfoModal v-if="isModalVisible" v-show="isModalVisible" @close="closeModal">
    <template v-slot:header>
      <h2 v-html="currentModal.title"></h2>
    </template>
    <template v-slot:body>
      <div v-html="currentModal.body"></div>
    </template>
    <template v-slot:footer>
      <b>To book an event at your venue, reach out to us at <ContactEmail />.</b>
    </template>
  </InfoModal>
</template>

<script>
import HeroImage from './components/HeroImage.vue'
import SectionTitle from './components/SectionTitle.vue'
import ContentCard from './components/ContentCard.vue'
import BioCard from './components/BioCard.vue'
import ContactEmail from './components/ContactEmail.vue'
import InfoModal from './components/InfoModal.vue'
import InlineCitation from './components/InlineCitation.vue'
import SocialIcon from './components/SocialIcon.vue'

export default {
  name: 'App',
  components: {
    HeroImage,
    SectionTitle,
    ContentCard,
    BioCard,
    ContactEmail,
    InfoModal,
    InlineCitation,
    SocialIcon
  },
  data() {
    return {
      isModalVisible: false,
      currentModal: null,
      modals: {
        'dragbrunch': {
          title: 'Drag Brunch',
          body: `<p>Drag brunch combines great food and cocktails with a rotation of diverse, talented local drag performers for a unique and fun event!</p>
          <p>A typical drag brunch show consists of two regular hosts (<span class="darkpink-text">Lucille</span> and <span class="darkpink-text">Violencia</span>) and two rotating guest performers. Each performer typically does 2-3 numbers, and the show is usually lasts ~2 hours.</p>
          <p>As hosts, we will work with you on all of the logistics including booking and coordinating performers, promoting the event, and keeping the show running smoothly!</p>`
        },
        'dragbingo': {
          title: 'Drag Bingo/Trivia',
          body: `<p>Drag bingo or trivia are great ways to bring in guests for a more casual and interactive drag experience.</p>
          <p>As hosts, <span class="darkpink-text">Lucille</span> and <span class="darkpink-text">Violencia</span> will lead rounds of either bingo or trivia throughout the night, interacting directly with the audience throughout. We will also each perform a number at some point during the event.</p>
          <p>These events are super easy to adjust depending on your needs. The format, number of winners, and number of rounds can all be customized. If you choose to offer gift cards as prizes, guests will be more likely to return to your venue and continue to purchase food/drinks in the future!</p>`,
        }
      },
    }
  },
  methods: {
    showModal(modalName) {
      document.body.scroll = "no";
      document.body.style.overflow = "hidden";
      this.isModalVisible = true;
      this.currentModal = this.modals[modalName]
    },
    closeModal() {
      document.body.scroll = "";
      document.body.style.overflow = "";
      this.isModalVisible = false;
      this.currentModal = null;
    }
  },
}
</script>

<style lang="scss">
@import "./styles/common.scss";

html, body, body * {
  margin: 0;
  padding: 0;
  min-width: 0;

  -webkit-box-sizing: border-box; /* Safari */
  -moz-box-sizing: border-box; /* Firefox */
  box-sizing: border-box; /* Opera/IE 8+ */

  /*outline: 1px solid red;*/
}

html {
  scroll-behavior: smooth;
}

body {
  @extend .flex;
  background-color: $body-bgcolor;
}

* {
  z-index: 2;
}

#app {
  display: grid;
  grid-template-columns: [left] 7% [content-start] 1fr [content-end] 7% [right];
  grid-template-rows: [nav-start] 4rem [nav-end hero-start] auto [hero-end main-start] auto [main-end footer-start] 2rem [footer-end];

  grid-template-areas:
    "nav nav nav"
    "hero hero hero"
    "left main right"
    "footer footer footer";

  width: 100%;
  height: 100%;

  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  background: $app-bgcolor;
  background: linear-gradient(150deg, $app-bgcolor 0%, $app-bgcolor2 33%, $app-bgcolor 66%, $app-bgcolor2 100%);
  color: $white;
}

/* BUTTONS */

div[aria-role="button"] {
  cursor: pointer;
}

a {
  @extend .darkpink-text;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:visited {
  color: $darkpink;
}

/* NAV */

nav {
  grid-area: nav;

  background-color: $body-bgcolor;
}

nav a {
  color: $white !important;
  text-decoration: none;
  border: none !important;
}

nav a:hover {
  text-decoration: none;
}

.nav-content {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  padding: 0 1rem;

  font-family: $title-font;
  font-weight: 600;
  text-transform: uppercase;

  gap: 2.5rem;
}

.nav-btn {
  @extend .flex;

  height: 1.5rem;

  transition: border 0.3s ease-out;
}

.nav-btn:hover {
  border-bottom: 2px solid white;
}

/* HERO */

@keyframes saturation {
  0% { filter: saturate(100%); }
  50% { filter: saturate(133%); }
  100% { filter: saturate(80%); }
}

#hero {
  grid-area: hero;
  position: relative;

  width: 100%;
  height: max-content;

  padding: 85px 0px;

  gap: 2rem;
}

.hero-bg {
  position: absolute;
  width: 100%;
  height: 100%;

  background: url('@/assets/transparent-bg.png');
  background-size: cover;
  background-position: top;

  animation: saturation 15s infinite alternate;
  z-index: 0;
}

.blurb {
  max-width: 600px;
  text-align: left;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 400;
}

/* MAIN CONTENT */

main {
  grid-area: main;

  text-align: left;

  padding: 1rem;
}

.content-section {
  @extend .flex;
  @extend .flex-top;
  @extend .flex-col;

  margin: 0 0 20px 0;
  padding: 2rem 0 2rem 0;
  width: 100%;
}

.content-section:last-child {
  margin-bottom: 100px;
}

.dragbrunch-card {
  right: 40px;
}

.dragbingo-card {
  left: 40px;
}

.content-section#contact p {
  text-align: center;
  max-width: 800px;
}

.contact-card {
  height: fit-content !important;
}

.contact-card p {
  font-size: 1.175rem;
}

.contact-card .card-content {
  height: 100%;
  padding: 0;
  padding-bottom: 15px;
  justify-content: space-between !important;
}

/* FOOTER */

footer {
  @extend .flex;

  grid-area: footer;

  background-color: $body-bgcolor;

  color: $gray;
  font-family: $title-font;
  font-size: 14px;
}

/* MOBILE */

@media only screen and (max-width: $mobile-width) {
  #app {
    grid-template-columns: [left] 2% [content-start] 1fr [content-end] 2% [right];
  }

  .nav-content {
    gap: 1rem;
  }

  .nav-btn {
    font-size: 0.9rem;
  }

  .blurb {
    font-size: 1rem;
    padding: 0 1.5rem;
  }

  main {
    margin: 0 0;
    padding: 0 0;
  }

  .content-section {
    margin: 0 0 30px 0;
    padding-top: 2rem;
  }

  .content-card {
    left: initial;
    right: initial;
  }

  .content-section:last-child {
    margin-bottom: 100px;
  }

  #contact {
    width: 90%;
    max-width: 800px;
  }

  footer {
    font-size: 12px;
  }
}

</style>
