<template>
    <h1 class="section-title">
        <slot></slot>
    </h1>
</template>

<script>
export default {
    name: 'SectionTitle'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-title {
    margin: 5px 0 20px 0;

    font-family: Fredoka One, Fredoka, sans-serif;
    font-size: 2.5rem;
    font-weight: 800;

    text-align: center;
    text-transform: uppercase;
}
</style>
