<template>
    <div class="modal-backdrop" @click="closeModal">
        <div class="modal" @click="nothing">
            <header class="modal-header">
                <slot name="header">
                    Header
                </slot>
                <button @click="closeModal">
                    X
                </button>
            </header>
            <section class="modal-body">
                <slot name="body">
                    Body
                </slot>
                <slot name="footer">
                    Footer
                </slot>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoModal',
    methods: {
        closeModal() {
            this.$emit('close');
        },
        nothing(e) {
            e.stopPropagation();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/common.scss";

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    backdrop-filter: blur(5px);
    z-index: 3;
}

.modal {
    width: 80%;
    max-width: 800px;

    background-color: $lightblue;
    color: $darkblue;

    border-radius: $border-radius;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    z-index: 10;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 17px 20px 0 20px;

    font-family: $title-font;


    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

.modal-header button {
    width: 2rem;
    aspect-ratio: 1 / 1;

    color: $white;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: $title-font;

    background-color: $darkpink;
    border-radius: 100%;

    border: none;
    cursor: pointer;

    user-select: none;
    transition: all 0.2s ease-out;
}

.modal-header button:hover {
    filter: brightness(95%);
    box-shadow: 0 0 2px $darkpink;
}

.modal-header button:active {
    filter: brightness(90%);
    box-shadow: 0 0 1px $darkpink;

}

.modal-body {
    padding: 20px;
    padding-top: 0;

    text-align: left;

    overflow-y: auto;
}

.modal-body * {
    margin: 0.75rem 0;
}

.modal-body *:first-child {
}

@media (max-width: $mobile-width) {
    .modal {
        width: 90%;
    }
}
</style>
