<template>
    <div class="bio-card" :class="align">
        <div class="bio-content">
            <h2 class="bio-title">
                {{  title  }}
            </h2>
            <p>
                <slot></slot>
            </p>
            <div class="bio-social">
                <slot name="social-icons"></slot>
            </div>
        </div>
        <div class="bio-img">
            <img :src="img" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BioCard',
    props: {
        title: String,
        img: String,
        align: String,
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/common.scss";

$text-width: 70%;

/* CARD CONTAINER */

.bio-card {
    position: relative;
    display: grid;
    grid-template-rows: auto;

    width: 100%;
    height: fit-content;
    max-width: 1000px;
    min-height: 250px;

    margin: 0 5px 0 5px;
    padding: 10px 30px 10px 10px;
    border-radius: $border-radius;

    color: $darkblue;
    background-color: $lightblue;
}

.bio-card.left {
    grid-template-columns: auto $text-width;

    grid-template-areas:
        "img content";
}

.bio-card.right {
    grid-template-columns: $text-width auto;

    grid-template-areas:
        "content img"
}

/* CONTENT AREA */

.bio-title {
    font-family: $title-font;
    font-size: 2.25rem;
}

.bio-content {
    display: flex;
    flex-direction: column;
    justify-content: center;


    grid-area: content;

    padding: 10px 0px;
    gap: 20px;
}

/* IMAGE AREA */

.bio-img {
    @extend .flex;
    grid-area: img;

    overflow: visible;
}

.bio-img img {
    @extend .flex;

    width: 100%;
    height: 100%;
    max-width: 300px;

    object-fit: cover;
    border-radius: $border-radius;

    overflow: visible;
}

/* SOCIAL AREA */

.bio-social {
    @extend .flex;
    @extend .flex-left;

    gap: 10px;
    filter: invert(1);
}

/* MOBILE */

@media only screen and (max-width: $mobile-width) {
    .bio-card {
        grid-template-rows: auto auto auto !important;
        grid-template-columns: 100% !important;
        grid-template-areas:
            "img"
            "content"
            "social" !important;

        transform: scale(0.9);
    }

    .bio-content {
        padding: 0 10px;
    }

    .bio-content * {
        width: 100%;
        text-align: center;
    }

    .bio-img {
        background-size: contain;

        width: 100%;
        height: 50vh;
    }

    .bio-social {
        padding: 20px 0;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}

</style>
