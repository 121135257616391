<template>
    <div class="social-icon">
        <a :href="href" target="_blank" rel="noreferrer noopener">
            <img :src="getIcon" />
        </a>
    </div>
</template>

<script>
export default {
    name: 'SocialIcon',
    props: {
        href: String,
        icon: String,
    },
    computed: {
        getIcon() {
            return require(`@/assets/social/${this.icon}.svg`);
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/common.scss";

img {
    width: 2rem;
    max-width: 100px;
    aspect-ratio: 1 / 1;
}

@media screen and (max-width: $mobile-width) {
    img {
        width: 1.5rem;
        max-width: 50px;
    }
}
</style>
